/*
 -  Main app
 */


// Imports
import $ from 'jquery';
import { debounce } from 'throttle-debounce';
import { v4 as uuidv4 } from 'uuid';
import NoSleep from 'nosleep.js';
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

const botDefined = window.botService !== undefined;
const Bot = botDefined ? window.botService.default : undefined;
const noSleep = new NoSleep();
const modal = document.getElementById("warningModal");
const stage = '_local_';
const environment = 'local';

// Game app
var Game = {
	// Global variables
	debounceTime: 150,
	loadingTime: 1000, // will be replaced with real caching time
	signLoadingTime: 2000, // will be replaced with real caching time
	characterLoadingTime: 750, // will be replaced with real caching time
	talkingTimeHuman: 3000, // will be replaced witht real talking human time
	talkingTimeBot: 3000, // will be replaced witht real talking human time
	talkingTimeContentShow: 250, // it depends on easing animation
	isTalkingHuman: false,
	isTalkingBot: false,
	isOpen: false,
	aircraftFly: false,
	skipping: false,
	sessionId: "",
	episodeKey: '5f57489a4a78d222a8521264',
	episodeKeys: ['5f57489a4a78d222a8521264', '5ffeb63ea12dcf5ff6b57391'],
	audios: {
	},
	// DOM references
	dom : {
		html : $( 'html' ),
		body : $( 'body' ),
		hill: $( '.hill' ),
		room : $( '.room' ),
		button : $( '.button' ),
		sign : $( '.sign' ),
		skip : $( '.skip' ),
		controls : $( '.controls' ),
		btnOpenClose : $( '.button-open-or-close' ),
		btnFly : $( '.button-talking-human' ),
		btnSkip : $( '.button-talking-bot' ),
		humanModality: $( '.human-modality' ),
		humanModalityContent: $( '.human-modality-content' ),
		humanModalityContentText: $( '.human-modality-content__text' ),
		botModality: $( '.bot-modality' ),
		botModalityContent: $( '.bot-modality-content' ),
		botModalityContentText: $( '.bot-modality-content__text' ),
		characters: $( '.characters' ),
		aircraft: $( '.aircraft'),
	},

	classes: {
		controlsShowed: 'controls--showed',
		roomClosed: 'room--closed',
		roomOpened: 'room--opened',
		roomLoaded: 'room--loaded',
		buttonOpened: 'button--opened',
		buttonClosed: 'button--closed',
		humanTalking: 'human-modality--talking',
		botTalking: 'bot-modality--talking',
		hillLoaded: 'hill--loaded',
		humanModalityLoaded: 'human-modality--loaded',
		humanModalityContentShowed: 'human-modality-content--showed',
		botModalityLoaded: 'bot-modality--loaded',
		botModalityContentShowed: 'bot-modality-content--showed',
		charactersShowed: 'characters--showed',
		aircraftFlying: 'aircraft--flying',
		signHidden: 'sign--hidden',
		devicePortrait: 'portrait',
		deviceLandscape: 'landscape',
	},

	translates: {
		btnOpen: 'Open',
		btnClose: 'Close',
		allowMicrophoneWarning: 'Příběh je nutné ovládat Vaším hlasem. Připojte prosím mikrofon a udělte v prohlížeči této stránce oprávnění ho používat.',
		browserWarning: 'Tento prohlížeč není podporován. Na iOS použijte prosím Safari, na ostatních platformách Chrome nebo Firefox.',
		oldBrowserWarning: 'Tato verze prohlížeče není podporována. Stáhněte si prosím aktuální verzi.',
		facebookWarning: 'Facebook prohlížeč není podporován. Klikněte prosím na menu (tři tečky) v pravém horním rohu a vyberte "Otevřít v Chrome/Safari"',
		audioWarning: 'Došlo k chybě při stahování audia. Zkuste hru prosím později.',
		ssmlWarning: 'Došlo k chybě při převodu textu na řeč. Zkuste hru prosím později.',
		botMissingError: 'Došlo k chybě služby. Zkuste hru prosím později.',
	},

  defBreakPoints : {
		md: 768,
		xl: 1200,
	},

  // Get Device Screen width
	getDeviceScreenX: function() {
		return window.innerWidth;
	},

  // Get Device Screen width
  getDeviceScreenY: function() {
	return window.innerHeight;
	},

	getDeviceOrientation: function() {
		var orientation;

		if ( Game.getDeviceScreenY() > Game.getDeviceScreenX() ) {
			orientation = Game.classes.devicePortrait;
		} else {
			orientation = Game.classes.deviceLandscape;
		}

		return orientation;
	},

	setDeviceOrientation: function() {
		Game.dom.body.addClass( Game.getDeviceOrientation() );

		$( window ).resize(debounce(Game.debounceTime, () => {
			Game.dom.body.removeClass( Game.classes.devicePortrait ).removeClass( Game.classes.deviceLandscape );
			Game.dom.body.addClass( Game.getDeviceOrientation() );
		}));
	},

	loading: function(callback) {
		Game.setDeviceOrientation();

		setTimeout(function() {
				Game.dom.sign.addClass( Game.classes.signHidden );
		}, Game.signLoadingTime);
		console.log('Loading.....');
		// Cache
		Game.dom.room.addClass( Game.classes.roomOpened );
		Game.dom.button.addClass( Game.classes.buttonOpened );

		setTimeout(function() {
			Game.dom.room.removeClass( Game.classes.roomOpened );
			Game.dom.room.addClass( Game.classes.roomClosed );
			Game.dom.button.removeClass( Game.classes.buttonOpened );
			Game.dom.button.addClass( Game.classes.buttonClosed );

			setTimeout(function() {
				Game.dom.room.removeClass( Game.classes.roomClosed );
				Game.dom.button.removeClass( Game.classes.buttonClosed );
				console.log('.....loaded');

				callback();
			},Game.loadingTime);
		},Game.loadingTime);
	},

	setupScene: function() {
		console.log('....setupScene.....');
		Game.roomAction('close');
		Game.dom.aircraft.removeClass( Game.classes.aircraftFlying );
		Game.dom.hill.addClass( Game.classes.hillLoaded );
		Game.dom.room.addClass( Game.classes.roomLoaded );

		setTimeout(function() {
			Game.dom.humanModality.addClass( Game.classes.humanModalityLoaded );
			Game.dom.botModality.addClass( Game.classes.botModalityLoaded );
			Game.dom.characters.addClass( Game.classes.charactersShowed );
			alignEpisodes();
		},Game.characterLoadingTime);
	},

	roomAction: function(action) {
		switch (action) {
			case 'close':
				Game.dom.room.removeClass( Game.classes.roomOpened );
				Game.dom.room.addClass( Game.classes.roomClosed );
				Game.dom.button.removeClass( Game.classes.buttonOpened );
				Game.dom.button.addClass( Game.classes.buttonClosed );
				Game.talkingBotCancel();
				Game.talkingHumanCancel();
				Game.isOpen = false;
				break;
			case 'open':
				Game.dom.room.removeClass( Game.classes.roomClosed );
				Game.dom.room.addClass( Game.classes.roomOpened );
				Game.dom.button.removeClass( Game.classes.buttonClosed );
				Game.dom.button.addClass( Game.classes.buttonOpened );
				Game.isOpen = true;
				break;
		}
	},

	talkingHuman: function(phrase) {
		var string = phrase || '...';

		if ( Game.isOpen ) {
			if ( Game.isTalkingHuman ) {
				return false;
			}
			if ( Game.isTalkingBot ) {
				Game.talkingBotCancel();
			}

			Game.isTalkingHuman = true;
			Game.dom.humanModality.addClass( Game.classes.humanTalking );
			Game.dom.humanModalityContentText.text( phrase );

			if (phrase.length > 0) {
				setTimeout(function(){
					Game.dom.humanModalityContent.addClass( Game.classes.humanModalityContentShowed );
				}, Game.talkingTimeContentShow);
			}
			console.log('....talkingHuman.....');

		}
	},

	talkingHumanCancel: function() {
		Game.isTalkingHuman = false;
		Game.dom.humanModality.removeClass( Game.classes.humanTalking );
		Game.dom.humanModalityContent.removeClass( Game.classes.humanModalityContentShowed );
	},

	talkingBot: function(phrase) {
		var string = phrase || '...';

		if ( Game.isOpen ) {
			if ( Game.isTalkingBot ) {
				return false;
			}

			if ( Game.isTalkingHuman ) {
				setTimeout( function() {
						Game.talkingHumanCancel()
				}, 4000);
			}

			Game.isTalkingBot = true;
			Game.dom.botModality.addClass( Game.classes.botTalking );
			Game.dom.botModalityContentText.text( phrase );
			if (phrase.includes('krouží')){
				Game.flyPlane();
			}

			if (phrase.length > 0) {
				setTimeout(function(){
        			Game.dom.botModalityContent.addClass( Game.classes.botModalityContentShowed );
				}, Game.talkingTimeContentShow);
				console.log('....talkingBot.....');
			}


		}
	},

	talkingBotCancel: function() {
		Game.isTalkingBot = false;
		Game.dom.botModality.removeClass( Game.classes.botTalking );
		Game.dom.botModalityContent.removeClass( Game.classes.botModalityContentShowed );
	},

	startConversation: function() {
			Game.roomAction('open');
			Game.dom.btnOpenClose.text( Game.translates.btnClose );
			if (botDefined) {
				for (const key in Game.audios) {
						Game.audios[key].play().then(_ => {
								Game.audios[key].pause();
								Game.audios[key].currentTime = 0;
								})
        }
				Game.startBot();
			} else {
				alert(Game.translates.botMissingError);
				Game.roomAction('close');
			}
	},

	requestMicrophone: function() {
			navigator.mediaDevices.getUserMedia({ audio: true })
						.then(function(stream) {
							const tracks = stream.getTracks();
            	tracks.forEach(function(track) {
                track.stop();
              });
							Game.startConversation();
						})
						.catch(function(err) {
							Sentry.captureException(err, {tags: {sessionId: Game.sessionId, source: "requestMicrophone"},});
							noSleep.disable();
							alert (Game.translates.allowMicrophoneWarning);
							Game.roomAction('close');
						});
	},

	initControls: function() {
		Game.dom.controls.addClass( Game.classes.controlsShowed );

		// Trigger open/close standby
		Game.dom.button.on('click', function() {
			const ua = navigator.userAgent;
			const isFacebook = (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
			noSleep.enable();
			if ( Game.isOpen ) {
				Game.roomAction('close');
				Game.dom.btnOpenClose.text( Game.translates.btnOpen );
				Game.bot.onStopClick();
				noSleep.disable();
			} else {
				const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
				if (isFacebook) {
					warningModal.style.display = "block";
				} else if (!navigator.mediaDevices.getUserMedia) {
					alert(Game.translates.oldBrowserWarning);
				} else if (!isSafari) {
					Game.requestMicrophone();
				} else {
					const isChrome = ua.match('CriOS');
					if (isChrome) {
						alert(Game.translates.browserWarning);
					} else {
						Game.startConversation();
					}
				}

			}
		});
		Game.dom.skip.on('click', function() {
			if (Game.isTalkingBot) {
					Game.bot.click('RESPONDING');
			}
		});

		// Trigger talk human
		Game.dom.btnFly.on('click', Game.flyPlane);

		// Trigger talk bot
		Game.dom.btnSkip.on('click', function() {
			if ( Game.isTalkingBot ) {
				Game.talkingBotCancel();
				Game.bot.click('RESPONDING');
			}
		});
	  },

	  flyPlane: function() {
	  		if (!Game.aircraftFly) {
    					Game.aircraftFly = true;
    					Game.dom.aircraft.addClass(Game.classes.aircraftFlying);
    					setTimeout(function(){
    						Game.dom.aircraft.removeClass(Game.classes.aircraftFlying);
    						Game.aircraftFly = false;
    					}, 6000
    					)
				}
	  },

	addIntroSound: function() {
			const introAudio = new Audio('assets/sound/intro.mp3');
			introAudio.play();
	},

	// Init function
	init: function() {
		Game.loading(function(){
			// Game.addIntroSound();
			Game.initBot();
			Game.initControls();
			const closeButton = document.getElementById('close');
			closeButton.addEventListener('click', function (){
				warningModal.style.display = "none";
			});

			Game.setupScene();
		});

		return this;
	},

	// START IMPLEMENTING
	setState: function(newState){
		if (newState['status'] === 'LISTENING') {
			Game.talkingBotCancel();
			Game.talkingHuman('');
		}
	},
	getStatusString: function(status){
			return status;
	},
	addMessage: function(type, text, image){
			if (text === null) {
				return
			}
			Game.talkingBotCancel();
			const talkingHum = Game.isTalkingHuman;
			Game.isTalkingHuman = false;
			if (type === 'sent') {
				//if (!Game.skipping){
					Game.talkingHuman(text)
				//}
			} else {
				Game.isTalkingHuman = talkingHum;
				Game.talkingBot(text)
			}
	},

	showLogs: function(logs){
			logs.forEach(l => {
				console.log(l.text);
			});
	},

	onError: function(error){
		noSleep.disable();
		Game.talkingBotCancel();
		Game.talkingHumanCancel();
		Game.roomAction('close');
		console.log(error.type);
		Sentry.captureException(error.message, {tags: {sessionId: Game.sessionId, source: "onError"},});
		if (error.type === 'Speech to text start error '){
				alert(Game.translates.allowMicrophoneWarning);
		} else if (error.type.startsWith('Audio error')){
				alert(Game.translates.audioWarning);
		} else if (error.message==='Bad Request' || error.message.startsWith('Invalid SSML request') || error.message.includes('Request contains an invalid argument'/* || MICROSOFT?*/)){
				alert(Game.translates.ssmlWarning);
		}
	},

	onEnd: function(){
			Game.roomAction('close');
			noSleep.disable();
	},

	getAttributes: function(){
		return {
    			clientType: 'vocaskovi:' + stage,
    			clientScreen: true,
    			clientBrowser: navigator.userAgent,
    		};
	},

	getUUID: function(){
			Game.sessionId = uuidv4();
			ga('set', 'sessionId', Game.sessionId);
			return Game.sessionId;
	},

	getVoice: function(){
			return undefined;
	},

	focusOnNode: function(){

	},

	play: function(sound){
//		if (sound === 'in' || sound === 'out') {
//				Game.audios[sound].play();
//		}
	},

	// STOP IMPLEMENTING

	initBot: function() {
		if (!botDefined){
			return
		}

		var sender = "";
		if (localStorage.getItem("sender") === null) {
      	sender = uuidv4();
      	localStorage.setItem("sender", sender);
    } else {
    		sender = localStorage.getItem("sender");
    }
    const clientCallback = {};
		clientCallback.setStatus = newState => {
			Game.setState(newState);
		};
		clientCallback.getStatusString = status => {
			Game.getStatusString(status);
		};
		clientCallback.addMessage = (type, text, image) => {
			Game.addMessage(type, text, image);
		};
		clientCallback.addLogs = logs => {
			Game.showLogs(logs);
		};
		clientCallback.onError = error => {
			Game.onError(error);
		};
		clientCallback.onEnd = Game.onEnd;
		clientCallback.getAttributes = Game.getAttributes;
		clientCallback.getUUID = Game.getUUID;
		clientCallback.getVoice = Game.getVoice;
		clientCallback.focusOnNode = Game.focusOnNode;
		clientCallback.play = sound => {
			Game.play(sound);
		};
		Game.bot = Bot('https://core.flowstorm.ai', 'vocaskovi:' + sender, true, clientCallback, false);
	},

	startBot: function() {
			Game.bot.init(Game.episodeKey, 'cs', true, true, '#intro');
	}

};

function alignEpisodes() {
		setTimeout ( function () {
				var header = document.getElementsByClassName("header")[0];
				var scene = document.getElementById("scene");
				var episodes = document.getElementById("episodes");
				var headerHeight = header.clientHeight;
				var windowHeight = window.innerHeight;
				var sceneHeight = scene.clientHeight;
				episodes.style.display = 'none';
				if (sceneHeight > (windowHeight - headerHeight + 10)) {
						episodes.style.top = headerHeight.toString() + 'px';
				} else {
						episodes.style.top = (windowHeight - sceneHeight + 10).toString() + 'px';
				}
		}, 200);
}

function switchEpisodes(number) {
		var otherNumber = (number + 1) % 2;
		var clickedEpisode = document.getElementById('episode-' + number.toString());
		var otherEpisode = document.getElementById('episode-' + otherNumber.toString());
		Game.episodeKey = Game.episodeKeys[number];
		clickedEpisode.classList.remove('episode--inactive');
		clickedEpisode.classList.add('episode--active');
		otherEpisode.classList.remove('episode--active');
		otherEpisode.classList.add('episode--inactive');
}

/* Document ready */
$(function() {

		window.onresize = alignEpisodes

		document.getElementById('episode-0').addEventListener('click', function () {switchEpisodes(0)})
		document.getElementById('episode-1').addEventListener('click', function () {switchEpisodes(1)})

		window.addEventListener('message', event => {
  			 if (event.data === 'BotStopEvent') {
						Game.bot.onStopClick();
  			 		Game.onEnd();
  			 }
  	 });

	window.addEventListener("beforeunload", function (e) {
			e = e || window.event
			if (e) {
				_gaq.push(['_trackPageview', "/quit"]);
			}
			return _gaq.push(['_trackPageview', "/quit"]);
	});

	Sentry.init({
  			dsn: 'https://07efa4d322224986a562cdde342ed978@o318069.ingest.sentry.io/5438713',
  			integrations: [
  			  new Integrations.BrowserTracing(),
  			],
  			tracesSampleRate: 1.0,
  			environment: environment,
	});

	var game = Game.init();
	console.log( game );
});